<template>
  <div class="login-wrapper">
  </div>
</template>

<script>
import { Base64 } from 'js-base64';
export default {
  name: "userVerify",
  data() {
    return {
      info:'',
      user:{}
    }
  },
  mounted() {
    if( this.$route.query.info ){
      this.info = this.$route.query.info
      if( this.info === '0' ){
        this.$message.error("用户登录失败");
      }else{
        const json = Base64.decode(this.info)
        if(json){
          this.user = JSON.parse(json)
          localStorage.removeItem('isSourceAdmin')
          //存储登录用户信息
          this.$store.state.loginRoot.loginDate = this.user.lastLoginTime
          this.$store.state.loginRoot.userInfo = this.user;
          localStorage.setItem("info", json);
          localStorage.setItem("loginDate", this.user.lastLoginTime);
          localStorage.setItem("isScrmPhone", this.user.isScrmPhone);
          //存储token
          localStorage.setItem("token", this.user.token);
          this.$router.push("/dashboard");
          return;
        }
        this.$message.error("用户登录失败");
      }
    }
  }

};
</script>